.loadingModal {
  height: 100%;
  background-color: #32425098;
  width: 100%;
  position: fixed;
  z-index: 92312343;
  top: 0;
  left: 0;
}
.insideModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460px;
  max-width: 90%;
  height: 210px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2em;
  box-sizing: border-box;
  z-index: 92312344;
  flex-direction: column;
  border-radius: 0.4em;
}

.lds_facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds_facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #33424f;
  animation: lds_facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds_facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds_facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds_facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds_facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


@media (max-width: 529px) {
  .insideModal h3 {
    font-size: 14px;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
