.centerHERE{
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    height: 78vh;
    display: flex;
    align-items: center;
    justify-content: center;
}.lds_roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds_roller div {
    animation: lds_roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds_roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #d00;
    margin: -4px 0 0 -4px;
  }
  .lds_roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds_roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds_roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds_roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds_roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds_roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds_roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds_roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds_roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds_roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds_roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds_roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds_roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds_roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds_roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds_roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds_roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }





  .lds_spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds_spinner div {
    transform-origin: 40px 40px;
    animation: lds_spinner 1.2s linear infinite;
  }
  .lds_spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #324250;
  }
  .lds_spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds_spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds_spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds_spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds_spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds_spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds_spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds_spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds_spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds_spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds_spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds_spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds_spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }